<template>
     <div class="form-group">
        <div style="display:flex;">
            <img :src="capcha" alt="Imga du capcha">
            <button type="button" @click="getCapcha">Recharge</button>
        </div>

        <input type="text" id="Capacha" name="capcha" placeholder="Capcha" required>
        <input type="hidden" id="capcha_key" name="key" :value="key">
    </div>
</template>
<script>
    import axios from 'axios'
     export default {
        name: 'CapchaComponent',
        data() {
            return {
                capcha: this.getCapcha(),
                key: null,
            }
        },

        methods: {
            getCapcha() {
                axios
                    .get('/ajax_regen_captcha')
                    .then(response => {
                        this.capcha = response.data;
                        this.key = response.data.split('?')[1];
                    });
            }
        }
    }
</script>
<style scoped lang="scss">
    .form-group {
        > div {
            margin: 1em 0;
            button {
                margin: 0 0.5em;
            }
        }
    }
</style>