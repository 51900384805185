<template>
    <div>
        <opinion-component v-for="opinion in opinions" :key="opinion.id"
            :scotch_image="scotch_image" 
            :star_image="star_image"
            :opinion="opinion"
        ></opinion-component>
    </div>
</template>

<script>
import OpinionComponent from './OpinionComponent.vue';
export default {
    props: ['opinions', 'scotch_image', 'star_image'],
    components: {
        "opinion-component": OpinionComponent
    },
}
</script>

<style scoped>

</style>