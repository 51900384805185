<template>
    <div class="partner-list">
        <partner-component v-for="partner in partners" 
            :key="partner.id" 
            :partner="partner"
            :base_url="base_url"
        ></partner-component>
    </div>
</template>

<script>
    import PartnerComponent from './PartnerComponent.vue';
    export default {
        name: 'PartnerListComponent',
        components: {
            'partner-component': PartnerComponent
        },
        props: ['partners', 'base_url'],

        mounted(){

        },
    }
</script>

<style scoped>

</style>