<template>
    <div>
        <div v-for="park_id in getParkIds()" :key="park_id">
            <span>{{ getParkName(park_id) }}</span>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>TARIFS</th>
                        <th>TARIFS REDUITS *</th>
                        <th v-if="park_id != 2">PASS SAISON **</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tarif in getFilteredTarif(park_id)" :key="tarif.id">
                        <td>
                            <b :style="'color:' + tarif.color">{{ tarif.title }}</b>
                            <span>{{ tarif.subtitle }}</span>
                        </td>
                        <td :style="'color:' + tarif.color">
                            {{ tarif.price }} €
                        </td>
                        <td :style="'color:' + tarif.color ">
                            {{ tarif.price_discounted }} €
                        </td>
                        <td v-if="tarif.has_season_pass" :style="'color:' + tarif.color + 'border-bottom: 1px solid #000'">
                                {{ tarif.price_season }} €
                        </td>
                        <td v-else style="border: none; border-top: 1px solid"></td>
                    </tr>
                </tbody>
            </table>
            <div class="small_lignes">
                <span><b>* Tarif réduit :</b> Groupe à partir de 15 personnes, ou porteurs d’une carte de réduction (CE et partenaires conventionnés).</span>
                <span v-if="park_id != 2"><b>** PASS Saison :</b> Abonnement annuel nominatif : accès à l’activité choisie en illimité durant une année civile. Venez quand vous voulez, amorti dès la 4ème sortie! </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TarifComponent',
        props: ['tarifs'],

        mounted() {
            
        },

        methods: {
            getParkIds() {
                return new Set(this.tarifs.map(tarif => tarif.parc_id));
            },

            getParkName(park_id) {
                var parkName = {
                    1: "CANOPY EN FORET ET BOIS DES GAULOIS",
                    2: "TYRO' SUR LOIRE"
                };

                return parkName[park_id];
            },

            getFilteredTarif(parc_id){
                return this.tarifs.filter(tarif => {
                    return tarif.parc_id == parc_id;
                });
            }
        },
    }
</script>

<style scoped>
    table {
        width: 50%;
        margin: auto;
    }
    tr td {
        border-top: 1px solid #000;
        border-right: 1px solid #000;
    }
    tr td:first-child {
        display: flex; 
        flex-direction: column;
        text-align: left;
        padding: 0.25em 0.5em;
        border-left: 1px solid #000;
    }
    tr:last-child td{
        border-bottom: 1px solid #000;
    }
    .small_lignes {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 0.75em;
        width: 50%;
        margin: 1em auto;
    }
</style>