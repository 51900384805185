<template>
    <div class="opinion-list">
        <opinion-list-component :opinions="opinionList" :scotch_image="scotch_image" :star_image="star_image"></opinion-list-component>
        <div class="opinion-buttons" v-if="getNbrPage > 1">
            <button type="button" aria-label="Precedent" @click="onChange(-1)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                </svg>
            </button>
            <button type="button" aria-label="Suivant" @click="onChange(1)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import OpinionListComponent from './OpinionListComponent.vue';
export default {
    name: "OpinionListView",
    components: {
        "opinion-list-component": OpinionListComponent
    },
    props: ['opinions', 'scotch_image', 'star_image'],
    data() {
        return {
            "page": 1,
            "quantity": 1
        }
    },
    mounted(){

    },
    computed: {
        opinionList(){
            if(this.opinions.length > 1){
                //Affichage 1 par 1
                if(this.getScreenSize() <= 480){
                    this.quantity = 1;
                }else if(this.getScreenSize() > 480 && this.getScreenSize() < 1024) {
                    this.quantity = 2;
                } else {
                    //Affichage 3 par 3
                    this.quantity = 3;
                }
                
                return this.getList(this.quantity);
            }
            return this.opinions;
        },
        getNbrPage(){
            return Math.ceil(this.opinions.length / this.quantity);
        }
    },
    methods: {
        getScreenSize(){
            return window.innerWidth;
        },
        onChange(value){
            if(value == -1 && this.page == 1){
                this.page = this.getNbrPage;
            }else if(this.page == this.getNbrPage && value == 1){
                this.page = 1;
            }else{
                this.page = this.page + value;
            }
        },
        getList(quantity){
            var index = this.page * quantity;
            var data = [];

            for(var i = quantity; i > 0; i--){
                if(this.opinions[index - i]){
                    data.push(this.opinions[index - i]);
                }
            }

            return data;
        }
    },
}
</script>

<style scoped>
    .opinion-buttons button{
        color: #fff;
        font-size: 0.1em;
        width: 1.5rem;
        height: 1.5rem;
        background: #000;
        display: block;
        border-radius: 50%;
        border: none;
        margin: 1em;
        font-size: 1em;
        cursor: pointer;
    }
</style>