<template>
    <a class="partner" :href="partner.url">
        <img :src="base_url + '/' + partner.logo" :alt="'Logo partenaire ' + partner.name ">
    </a>
</template>

<script>
    export default {
        name: 'PartnerComponent',
        props: ['partner', 'base_url'],
        mounted(){

        },
    }
</script>

<style scoped>

</style>