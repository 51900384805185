<template>
<div class="parcours-carousel">
        <course-component v-for="course in courses" :key="course.id"
            :course="course"
            :base_url="base_url"
        ></course-component>
    </div>
</template>

<script>
    import CourseComponent from './CourseComponent.vue';
    export default {
        name: "CourseListComponent",
        components: {
            "course-component": CourseComponent
        },
        props: ['courses', 'base_url'],
        
    }
</script>

<style scoped>

</style>