import { createApp } from 'vue';

import OpinionListView from './components/front/opinion-list/OpinionListView.vue';
import CourseListView from './components/front/course-list/CourseListView.vue';
import PartnerListView from './components/front/partner-list/PartnerListView.vue';
import SchedulerView from './components/front/scheduler/SchedulerView.vue';
import TarifComponent from './components/front/tarifs/TarifComponent.vue';
import CapchaComponent from './components/CapchaComponent.vue';

// Vue.component('form-alert-button', require('./components/form/AlertComponent.vue').default);

createApp({
    components: {
        OpinionListView, CourseListView, PartnerListView, SchedulerView, TarifComponent, CapchaComponent
    }
}).mount('#app');

//Ouverture du menu burger
document.getElementById('button-burger').addEventListener('click', function() {
    this.classList.toggle('open-burger');
    document.querySelector('nav').classList.toggle('open-burger');
});
